import React, { Component } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import "./Navbar.css";

export default class Navbar extends Component {
    scrollToTop = () => {
        scroll.scrollToTop();
    };

    render() {
        return (
            <header role="banner" id="qbootstrap-header">
                <div className="container">
                    <nav className="navbar navbar-default">
                        <div className="navbar-header">
                            <a className="js-qbootstrap-nav-toggle qbootstrap-nav-toggle" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar"><i></i></a>
                            <a className="navbar-brand" style={{ cursor: 'pointer' }} onClick={this.scrollToTop}>DC Wedding</a>
                        </div>
                        <div id="navbar" className="navbar-collapse collapse">
                            <ul className="nav navbar-nav navbar-right">
                                <li className="nav-items"><Link activeClass="active" style={{ cursor: 'pointer' }} to="home" spy={true} smooth={true} offset={-70} duration={500} >Home</Link></li>
                                <li className="nav-items"><Link activeClass="active" style={{ cursor: 'pointer' }} to="qbootstrap-couple" spy={true} smooth={true} offset={-70} duration={500} >Bride &amp; Groom</Link></li>
                                <li className="nav-items"><Link activeClass="active" style={{ cursor: 'pointer' }} to="qbootstrap-started" spy={true} smooth={true} offset={-70} duration={500} >RSVP</Link></li>
                                <li className="nav-items"><Link activeClass="active" style={{ cursor: 'pointer' }} to="qbootstrap-when-where" spy={true} smooth={true} offset={-70} duration={500} >When &amp; Where</Link></li>
                                <li className="nav-items"><Link activeClass="active" style={{ cursor: 'pointer' }} to="footer" spy={true} smooth={true} offset={-70} duration={500} >The Proposal</Link></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        );
    };
};