import React from "react";
import { FlipClock } from "reactflipclock-js";

const Home = () => {
    return (
        <div id="home" className="qbootstrap-hero" data-section="home">
            <div className="qbootstrap-overlay"></div>
            <div className="qbootstrap-cover text-center" data-stellar-background-ratio="0.5">
                <div className="display-t">
                    <div className="display-tc">
                        <div className="container">
                            <div className="col-md-10 col-md-offset-1">
                                <div className="svg-sm colored">
                                    <h1 className="holder"><span>We're getting married</span></h1>
                                    <h2>Carmen &amp; Danie</h2>
                                    <p>2020.12.16</p>
                                    <img src="images/flaticon/hiclipart1.png" className="svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <FlipClock clockFace="YearlyCounter" />
                </div>
            </div>
        </div>
    );
};

export default Home;