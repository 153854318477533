import React, { useState, useEffect } from 'react';
import { Link, animateScroll as scroll } from "react-scroll";

const ScrollToTopButton = () => {
    const [showTopButton, setShowTopButton] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                setShowTopButton(true);
            } else {
                setShowTopButton(false);
            }
        });
    }, []);
    const handleScrollToTop = () => {
        scroll.scrollToTop();
    };

    return (
        <div>
            {showTopButton && (
                <a style={{ textDecoration: 'none' }} id="button" onClick={handleScrollToTop}></a>
            )}
        </div>
    );
};

export default ScrollToTopButton;