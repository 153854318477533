import React from 'react';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import GroomBride from './pages/GroomBride';
import Rsvp from './pages/Rsvp';
import Venue from './pages/Venue';
import TheProposal from './components/TheProposal';
import ScrollToTopButton from './components/ScrollToTopButton';

class App extends React.Component {
  render() {
    return (
      <div className="bg">
        <Navbar />
        <ScrollToTopButton />

        <Home />
        <GroomBride />
        <Rsvp />
        <Venue />
        <TheProposal />
      </div>
    );
  }
}

export default App;
