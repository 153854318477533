import React from "react";

const TheProposal = () => {
    return (
        <footer id="footer" role="contentinfo" data-section="the-proposal">
            <section id="timeline">

                <div className="tl-item">

                    <div className="tl-bg" style={{ backgroundImage: "url('images/DC_proposal_2.jpg')" }}></div>

                    <div className="tl-year">
                        <p className="source-sans-pro">WE</p>
                    </div>

                </div>

                <div className="tl-item">

                    <div className="tl-bg" style={{ backgroundImage: "url('images/DC_proposal_4.jpg')" }}></div>

                    <div className="tl-year">
                        <p className="source-sans-pro">ARE</p>
                    </div>

                </div>

                <div className="tl-item">

                    <div className="tl-bg" style={{ backgroundImage: "url('images/DC_proposal_5.jpg')" }}></div>

                    <div className="tl-year">
                        <p className="source-sans-pro">GETTING</p>
                    </div>

                </div>

                <div className="tl-item">

                    <div className="tl-bg" style={{ backgroundImage: "url('images/DC_proposal_8.jpg')" }}></div>

                    <div className="tl-year">
                        <p className="source-sans-pro">MARRIED</p>
                    </div>

                </div>
            </section>
        </footer>
    );
};

export default TheProposal;