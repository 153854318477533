import React from "react";

const Rsvp = () => {
    return (
        <div id="qbootstrap-started" className="qbootstrap-bg rsvp-cover" data-section="rsvp" data-stellar-background-ratio="0.5">
            <div className="overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                        <div className="col-md-12 text-center section-heading svg-sm colored">
                            <h2>RSVP</h2>
                            <div className="row">
                                <div className="col-md-10 col-md-offset-1 subtext">
                                    <h1 className="source-sans-pro">Join Carmen & Danie on the 16th of December to celebrate their union</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10 col-md-offset-1">
                        <form className="form-inline" id="rsvp">
                            <div className="col-md-4 col-sm-4">
                                <div className="form-group">
                                    <label htmlFor="name" className="sr-only">Name</label>
                                    <input type="text" className="form-control" id="name" placeholder="Name" required disabled autoComplete="off" />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="form-group">
                                    <label htmlFor="contactNumber" className="sr-only">Contact Number</label>
                                    <input type="tel" pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$" className="form-control" id="contactNumber" placeholder="Contact number" required disabled />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="form-group">
                                    <label htmlFor="email" className="sr-only">Email (Optional)</label>
                                    <input type="email" className="form-control" id="email" placeholder="Email (Optional)" disabled autoComplete="off" />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="form-group">
                                    <label htmlFor="dietaryRequirements" className="sr-only">Any dietary requirements?</label>
                                    <input type="text" className="form-control" id="dietaryRequirements" placeholder="Dietary requirements" disabled />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="form-group">
                                    <label htmlFor="numberAttending" className="sr-only">Number attending</label>
                                    <input type="number" min="0" max="5" className="form-control" id="numberAttending" placeholder="Number attending" required disabled />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <button type="submit" className="btn btn-default btn-block" id="submitAttendance" disabled>
                                    <i id="sendingRsvp" className="fa"></i>I am Attending
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Rsvp;