import React from "react";

const Venue = () => {
    return (
        <div id="qbootstrap-when-where" data-section="when-where">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                        <div className="col-md-12 text-center section-heading svg-sm colored">
                            <h2>Ceremony &amp; Reception</h2>
                            <div className="row">
                                <div className="col-md-10 col-md-offset-1 subtext">
                                    <h3>Experience the sound of birds chirping, the flow of water from the stream below and dappled sunlight casting its blessing on to you and your loved ones.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row row-bottom-padded-md">
                    <div className="col-md-6 text-center">
                        <div className="wedding-events">
                            <div className="ceremony-bg" style={{ backgroundImage: "url('images/ceremony-4514.jpg')" }}></div>
                            <div className="desc">
                                <h3>Wedding Ceremony</h3>
                                <div className="row">
                                    <div className="col-md-2 col-md-push-5">
                                        <div className="icon-tip">
                                            <span className="icon"><i className="icon-heart-o"></i></span>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-md-pull-1">
                                        <div className="date">
                                            <i className="icon-calendar"></i>
                                            <span>Wednesday</span>
                                            <span>16 Dec. 2020</span>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-md-pull-1">
                                        <div className="date">
                                            <i className="icon-clock2"></i>
                                            <span>16:30</span>
                                            <span>17:30</span>
                                        </div>
                                    </div>
                                </div>
                                <p>Guests will make their way through a tranquil nature walk towards the epitome of a fairytale set-up. In the "open-air-church" guests will be seated in between the towering wattle trees. Guests are welcome to start arriving at 16:00 to get the perfect seat. The ceremony will start at 16:30.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 text-center">
                        <div className="wedding-events">
                            <div className="ceremony-bg" style={{ backgroundImage: "url('images/DieWoud-TheLeSueurs-01.jpg')" }}></div>
                            <div className="desc">
                                <h3>Wedding Reception</h3>
                                <div className="row">
                                    <div className="col-md-2 col-md-push-5">
                                        <div className="icon-tip">
                                            <span className="icon"><i className="icon-heart-o"></i></span>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-md-pull-1">
                                        <div className="date">
                                            <i className="icon-calendar"></i>
                                            <span>Wednesday</span>
                                            <span>16 Dec. 2020</span>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-md-pull-1">
                                        <div className="date">
                                            <i className="icon-clock2"></i>
                                            <span>18:00</span>
                                            <span>23:30</span>
                                        </div>
                                    </div>
                                </div>
                                <p>For the celebration, a clear glass marquee tent will house our guests, allowing the outside elements to be a part of the setting. As the sun begins to dip behind the horizon and the stars come out to play the dance floor and atmosphere will come to life like an enchanted affair.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gmap">
                    <iframe style={{ width: '100%', height: '100%' }} id="gmap_canvas" src="https://maps.google.com/maps?q=die%20woud&t=&z=8&ie=UTF8&iwloc=&output=embed"></iframe>
                </div>
            </div>
        </div>
    );
};

export default Venue;