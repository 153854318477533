import React from "react";

const GroomBride = () => {
    return (
        <div id="qbootstrap-couple" className="qbootstrap-section-gray" data-section="groom-bride">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                        <div className="col-md-12 text-center section-heading svg-sm colored">
                            <p>I choose you. And I'll choose you, over and over and over. Without pause, Without a doubt, in a heartbeat. I'll keep choosing you.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 col-md-offset-2 text-center">
                        <div className="col-md-5 col-sm-5 col-xs-5 nopadding">
                            <img src="images/bride.jpg" className="img-responsive" alt="" />
                            <h3>Carmen I Fleming</h3>
                            <span>Bride</span>
                        </div>
                        <div className="col-md-2 col-sm-2 col-xs-2 nopadding">
                            <h2 className="amp-center">
                            </h2>
                        </div>
                        <div className="col-md-5 col-sm-5 col-xs-5 nopadding">
                            <img src="images/groom.jpg" className="img-responsive" alt="" />
                            <h3>Daniel J Strydom</h3>
                            <span>Groom</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GroomBride;